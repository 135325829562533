<template>

    <Section v-if="!parcel_sheet">
        <Container>
          <p>
            Aucune parcelle n'a été ajoutée à l'essai.
          </p>
        </Container>
      </Section>
      <Parcel v-if="parcel_sheet" :parcel="parcel" :parcel_sheet="parcel_sheet" map />
    </template>
<script>
import Parcel from '@/views/componentsViews/parcel/Parcel.vue'
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'

export default {
  name: 'ParcelSubview',
  components: {
    Parcel,
    Section,
    Container,
  },
  props: {
    pageTitle: {
      type: String,
    },
    essai: {
      type: Object,
    },
  },
  data() {
    return {
      parcel: this.essai?.parcelle,
      parcel_sheet: this.essai?.parcelle_fiche,
    }
  },
  emits: ['updateEssais'],
  mounted() {},
  methods: {},
}
</script>
